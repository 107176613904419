<template>
    <div class="map-page map-page-list map-page-topics-list" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <div class="map-admin-list">
                <header class="map-admin-list-header">
                    <h1 v-on:click="edit_type = !edit_type">List of {{ current_edit === 'topics' ? 'languages' : current_edit }}</h1>
                    <button class="map-button map-color-primary map-download-csv" @click="create_new">Create new {{ current_edit === 'topics' ? 'Language' : 'Tag' }}</button>
                    <ul
                        class="map-types-list"
                        :class="{ 'map-expanded': edit_type }"
                        >
                        <li
                            :class="{ 'map-selected': current_edit === 'topics' }"
                            v-on:click="change_edit_type('topics')"
                            >
                            Languages
                        </li>
                        <li
                            :class="{ 'map-selected': current_edit === 'tags' }"
                            v-on:click="change_edit_type('tags')"
                            >
                            Tags
                        </li>
                    </ul>
                </header>
                <div class="map-table map-topics-table" :class="{ 'map-loading': loading }">
                    <header>
                        <div class="map-table-col">#</div>
                        <div class="map-table-col">Name</div>
                        <div class="map-table-col" v-if="logged_user.role === 'super-admin'">Actions</div>
                    </header>
                    <div class="map-table-body">
                        <loader />
                        <p class="map-table-empty" v-if="!has_topics && !loading">There are no items to display.</p>
                        <div class="map-table-row" v-for="(item, key) in this[current_edit]" :key="key">
                            <div class="map-table-col">{{ key + 1 }}</div>
                            <div class="map-table-col">
                                <template v-if="item.edit">
                                    <base-input
                                        placeholder="Name"
                                        v-model="item._name"
                                        :error="item.error"
                                        >
                                    </base-input>
                                    <button class="map-button map-color-primary map-button-save" @click="save(item)">Save</button>
                                </template>
                                <template v-else>{{ item.name }}</template>
                            </div>
                            <div class="map-table-col map-no-bg" v-if="logged_user.role === 'super-admin'">
                                <button class="map-button map-color-primary map-button-edit" @click="item.edit = !item.edit"></button>
                                <button
                                    class="map-button map-color-primary map-button-remove"
                                    @click="remove_item(item.name, key)"
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '@/components/Loader';
    import BaseInput from '@/components/Inputs/BaseInput';

    import { TopicService, TagService } from '@/services';

    export default
    {
        name: 'PostsList',
        components:
        {
            Loader,
            BaseInput,
        },
        data()
        {
            return {
                loading: false,
                edit_type: false,
                has_topics: false,
                has_tags: false,
                current_edit: 'topics',
                topics: [],
                tags: [],
            };
        },
        computed:
        {
            logged_user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            await this.get_topics();
        },
        methods:
        {
            async get_topics()
            {
                this.loading = true;

                this.topics = (await TopicService.all()).data;

                this.has_topics = this.topics && this.topics.length;

                this.topics = this.topics.map((item) =>
                {
                    return {
                        _name: item,
                        name: item,
                        edit: false,
                    };
                });

                this.loading = false;
            },

            async get_tags()
            {
                this.loading = true;

                this.tags = (await TagService.all()).data;

                this.has_tags = this.tags && this.tags.length;

                this.tags = this.tags.map((item) =>
                {
                    return {
                        _name: item,
                        name: item,
                        edit: false,
                    };
                });

                this.loading = false;
            },

            create_new(type)
            {
                if (type === 'topics')
                {
                    this.topics.unshift({
                        _name: '',
                        name: '',
                        edit: true,
                        new: true,
                    });
                }
                else
                {
                    this.tags.unshift({
                        _name: '',
                        name: '',
                        edit: true,
                        new: true,
                    });
                }
            },

            async change_edit_type(type)
            {
                if (type === 'topics')
                {
                    await this.get_topics();
                }
                else
                {
                    await this.get_tags();
                }

                this.current_edit = type;
                this.edit_type = false;
            },

            async remove_item(name, index)
            {
                if (confirm('Are you sure you want to remove this item?'))
                {
                    if (this.current_edit === 'topics')
                    {
                        await TopicService.remove(name);

                        this.topics.splice(index, 1);
                    }
                    else
                    {
                        await TagService.remove(name);

                        this.tags.splice(index, 1);
                    }
                }
            },

            async save(item)
            {
                if (!item._name || item._name.trim() === '')
                {
                    item.error = 'Name should not be empty';

                    return ;
                }

                item.error = '';

                const name = (item.new ? item._name : item.name);

                if (this.current_edit === 'topics')
                {
                    if (item.new)
                    {
                        TopicService.create({ name: item._name }).then(() =>
                        {
                            item.edit = false;
                            item.new = false;
                            item.name = item._name;
                            this.$toast.success('Topic was successfully created!');
                        },
                        (error) =>
                        {
                            if (error.data.message)
                            {
                                this.$toast.error(error.data.message);
                            }
                            else
                            {
                                this.$toast.error('Something went wrong. Please try again!');
                            }
                        });
                    }
                    else
                    {
                        TopicService.update(name, { name: item._name }).then(() =>
                        {
                            item.edit = false;
                            item.new = false;
                            item.name = item._name;
                            this.$toast.success('Topic was successfully updated!');
                        },
                        (error) =>
                        {
                            if (error.data.message)
                            {
                                this.$toast.error(error.data.message);
                            }
                            else
                            {
                                this.$toast.error('Something went wrong. Please try again!');
                            }
                        });
                    }
                }
                else
                {
                    if (item.new)
                    {
                        TagService.create({ name: item._name }).then(() =>
                        {
                            item.edit = false;
                            item.new = false;
                            item.name = item._name;
                            this.$toast.success('Tag was successfully created!');
                        },
                        (error) =>
                        {
                            if (error.data.message)
                            {
                                this.$toast.error(error.data.message);
                            }
                            else
                            {
                                this.$toast.error('Something went wrong. Please try again!');
                            }
                        });
                    }
                    else
                    {
                        TagService.update(name, { name: item._name }).then(() =>
                        {
                            item.edit = false;
                            item.new = false;
                            item.name = item._name;
                            this.$toast.success('Tag was successfully updated!');
                        },
                        (error) =>
                        {
                            if (error.data.message)
                            {
                                this.$toast.error(error.data.message);
                            }
                            else
                            {
                                this.$toast.error('Something went wrong. Please try again!');
                            }
                        });
                    }
                }
            },
        },
    }
</script>
